import { IoIosGitBranch } from "react-icons/io";
import { BsFillPersonCheckFill } from "react-icons/bs";
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import FactoryIcon from '@mui/icons-material/Factory';
import WifiIcon from '@mui/icons-material/Wifi';
import SsidChartIcon from '@mui/icons-material/SsidChart';

export const cardsContent = [
    {
        title: "Inteligência Artificial",
        text: "Criação de soluções com estratégias inteligentes, aprendizado de máquina e visão computacional.",
        cards: [
            {
                title: "Análise de tecnologia",
                text: "Estudo do problema para a definição das melhores técnicas de inteligência para a resolução.",
                icon: <IoIosGitBranch/>
            },
            {
                title: "Visão computacional",
                text: "Desenvolvimento de ferramentas.",
                icon: <BsFillPersonCheckFill/>
            },
            {
                title: "Data Science",
                text: "Desenvolvimento de ferramentas de geração de informação a partir de dados.",
                icon: <DeveloperModeIcon sx={{ fontSize: 40 }}/>
            }
        ]
    },
    {
        title: "Desenvolvimento web e mobile",
        text: "Desenvolvimento de soluções customizadas para nossos clientes.",
        cards: [
            {
                title: "UX/UI",
                text: "Aplicação de guidelines para tornas as soluções mais intuitivas e fáceis de usar, com foco em uma ótima experiência para o usuário.",
                icon: <FactoryIcon sx={{ fontSize: 40 }}/>
            },
            {
                title: "Desenvolvimento ágil",
                text: "Aplicação de metodologias ágeis de desenvolvimento que envolvem o cliente em todas as etapas do processo de desenvolvimento.",
                icon: <WifiIcon sx={{ fontSize: 40 }}/>
            },
            {
                title: "Tecnologias de ponta",
                text: "Utilização de tecnologias de ponta para garantir eficiência, segurança e geração de valor para nossos clientes.",
                icon: <SsidChartIcon sx={{ fontSize: 40 }}/>
            }
        ]
    },
    {
        title: "Consultoria em projetos",
        text: "Mussum Ipsum, cacilds vidis litro abertis. Per aumento de cachacis, eu reclamis.Tá deprimidis, eu conheço uma cachacis que pode alegrar sua vidis.Viva Forevis aptent taciti sociosqu ad litora torquent.Mé faiz elementum girarzis, nisi eros vermeio.",
        cards: [
            {
                title: "Desenvolvimento de projetos científicos",
                text: "Criação de projetos científicos e de inovação tecnológica com foco na obtenção de recursos.",
                icon: <FactoryIcon sx={{ fontSize: 40 }}/>
            },
            {
                title: "Acompanhamento de projetos científicos",
                text: "Auxílio na execução de projetos de modo a garantir os resultados propostos.",
                icon: <FactoryIcon sx={{ fontSize: 40 }}/>
            },
            {
                title: "Consultoria em inovação",
                text: "...",
                icon: <FactoryIcon sx={{ fontSize: 40 }}/>
            },
        ]
    }
];