import { Box, ButtonBase, Typography, useTheme } from '@mui/material';
import { motion } from 'framer-motion';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const buttonVariants = (primary, secondary) => ({
    hover: {
        backgroundColor: [secondary, primary]
    },
    default: {
        backgroundColor: [secondary]
    }
});

const Social = () => {
    return (
        <Box>
            <Typography
                variant={"h5"}
                sx={{
                    mb: 2
                }}
            >
                <strong>Redes Sociais</strong>
            </Typography>
            <ButtonIcon
                href={"https://www.linkedin.com/company/enterscience/"}
            >
                <LinkedInIcon/>
            </ButtonIcon>
            <ButtonIcon
                href={"https://www.facebook.com/enterscience"}
            >
                <FacebookIcon/>
            </ButtonIcon>
            <ButtonIcon
                href={"https://www.instagram.com/enterscience_br/"}
            >
                <InstagramIcon/>
            </ButtonIcon>
        </Box>
    );
}

const ButtonIcon = props => {
    const {
        href,
        children
    } = props;

    const theme = useTheme();

    return (
        <ButtonBase
            component={motion.button}
            variants={buttonVariants(theme.palette.primary.main, theme.palette.secondary.main)}
            initial={"default"}
            whileHover={"hover"}
            onClick={() => window.open(href, "_blank")}
            sx={{
                padding: 1,
                borderRadius: 1,
                color: "#fff",
                margin: .3
            }}
        >
            {children}
        </ButtonBase>
    );
}

export default Social;