import { Navigate, Route, Routes } from "react-router-dom";

import Landing from "pages/Landing";
import Login from "pages/Login";
import UnderConstruction from "pages/UnderConstruction";

// const PublicRoutes = () => {
//     return (
//         <Routes>
//             <Route path={"/"} element={<Landing/>}/>
//             {/* <Route path={"*"} element={<Navigate to={"/"}/>}/> */}
//         </Routes>
//     );
// }

const publicRoutes = [
    { path: "/", element: <Landing/> },
    { path: "/blog", element: <UnderConstruction/> },
    { path: "/carreiras", element: <UnderConstruction/> }
];

export default publicRoutes;