import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { Post } from "requests";
import { getCookie, setCookie } from "utils/cookie";
import { API_URL } from "variables";

const useLogin = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    const { mutate: loginMutate } = useMutation(async () => {
        const form = new FormData();
        form.append("email", email);
        form.append("password", password);

        return await Post(`${API_URL}/auth/login`, form);
    }, {
        onSuccess: async (data) => {
            if (data?.httpCode !== 200) return;
            if (data?.access_token) {
                setCookie("token", data?.access_token, 3600 * 24 * 7);
                navigate("/admin");
            }
        }
    });

    const handleEmail = e => setEmail(e.target.value);
    const handlePassword = e => setPassword(e.target.value);

    return {
        email,
        password,
        handleEmail,
        handlePassword,
        loginMutate
    };
}

export default useLogin;