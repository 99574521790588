import Actions from "./Actions";

const { TableRow, TableCell } = require("@mui/material")

const Board = (props) => {

    const {
        id,
        name,
        email,
        phone,
        need
    } = props;

    return (
        <TableRow>
            <TableCell>{name}</TableCell>
            <TableCell>{email}</TableCell>
            <TableCell>{phone}</TableCell>
            <TableCell>{need}</TableCell>
            <TableCell>
                <Actions
                    id={id}
                />
            </TableCell>
        </TableRow>
    );
}

export default Board;