import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Post } from "requests";
import { API_URL } from "variables";

const useLandingForm = () => {

    const { register, handleSubmit, control, reset, formState: { errors } } = useForm({
        defaultValues: {
            need: "Inteligência Artificial"
        }
    });

    const { mutate } = useMutation(async (data) => {
        return await Post(`${API_URL}/clients/guest/create`, data);
    }, {
        onSuccess: (data) => {
            if (data?.httpCode !== 200) throw Error;
            reset();
        }
    });

    const onSubmit = handleSubmit((data) => mutate(JSON.stringify(data)));

    return {
        register,
        control,
        errors,
        onSubmit
    };
}

export default useLandingForm;