import { Box, Collapse, List, ListItem } from "@mui/material";
import { motion } from "framer-motion";
import { itemsListData } from "modules/Menubar/data";
import { useNavigate } from "react-router-dom";

const containerVariants = {
    triggered: {
        top: "60px"
    },
    default: {
        top: "90px"
    }
};

const TopList = props => {

    const {
        open,
        scrollTrigger,
        items = itemsListData,
        ...others
    } = props;

    const navigate = useNavigate();

    return (
        <Box
            component={motion.div}
            position={"fixed"}
            left={0}
            bgcolor={"white"}//#################temporary bgcolor
            width={"100%"}
            variants={containerVariants}
            animate={scrollTrigger ? "triggered" : "default"}
            zIndex={1200}
            sx={{
                display: {
                    md: "none"
                }
            }}
            {...others}
        >
            <Collapse
                in={open}
            >
                <List>
                    {items?.map((item, itemKey) => (
                        <ListItem
                            button
                            component={"a"}
                            href={item?.anchor}
                            key={itemKey}
                            onClick={() => item?.to && navigate(item?.to)}
                            sx={{
                                textDecoration: "none",
                                color: "inherit"
                            }}
                        >
                            {item?.label}
                        </ListItem>           
                    ))}
                </List>
            </Collapse>
        </Box>
    );
}

export default TopList;
