import { Box, Typography, useTheme } from "@mui/material";
import Carousel from "components/Carousel";
import Board from "./Board";
import { cases } from "./data";

const SuccessCases = () => {

    const theme = useTheme();

    return (
        <Box
            id={"successCases"}
            sx={{
                padding: {
                    xs: theme.spacing(8, 0, 0, 0),
                    md: theme.spacing(8, 8, 0, 8)
                }
            }}
        >
            <Typography
                variant={"h1"}
                align={"center"}
                sx={{
                    marginTop: 4
                }}
            >
                Casos de <strong>Sucesso</strong>
            </Typography>
            <Carousel
                slidesPerView={1}
                margin={theme.spacing(4, 0)}
                breakpoints={{
                    1000: {
                        slidesPerView: 2
                    }
                }}
                slidesProps={{
                    style: {
                        height: "auto",
                        padding: theme.spacing(4, 0)
                    }
                }}
            >
                {cases?.map((sCase, key) => (
                    <Board
                        key={key}
                        {...sCase}
                    />
                ))}
            </Carousel>
        </Box>
    );
}

export default SuccessCases;