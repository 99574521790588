import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import AdminPanel from "./AdminPanel";
import 'react-pro-sidebar/dist/css/styles.css';
import ClientsRegisters from "./Clients/Registers";

const AdminContainer = () => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                height: "100vh"
            }}
        >
            <AdminPanel/>
            <Box
                sx={{
                    width: "100%",
                    "& > *": {
                        width: "100%"
                    }
                }}
            >
                <Routes>
                    <Route
                        path="/"
                        element={<p>home dashboard</p>}
                    />
                    <Route
                        path="/clients/registers"
                        element={<ClientsRegisters/>}
                    />
                </Routes>
            </Box>
        </Box>
    );
}

export default AdminContainer;