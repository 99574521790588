import { Box, useTheme } from "@mui/material";
import { cardsContent } from "./data";

import Board from "./Board";

const CardGroup = () => {

    const theme = useTheme();

    return (
        <Box
            marginTop={theme.spacing(6)}
            sx={{
                display: "flex",
                flexDirection: {
                    xs: "column",
                    md: "row"
                },
                padding: {
                    xs: 1,
                    md: theme.spacing(0, 6)
                }
            }}
        >
            {cardsContent?.map((card, key) => (
                <Board
                    {...card}
                    key={key}
                />
            ))}
        </Box>
    );
}

export default CardGroup;