import { Box, Typography, useTheme } from "@mui/material";
import { motion } from "framer-motion";

import wave from "./assets/wave.svg";
import CardGroup from "./CardGroup";

const inView = {
    opacity: 1,
    y: 0,
    transition: {
        opacity: {
            duration: 1
        },
        y: {
            duration: .5
        }
    }
};

const Pillars = () => {

    const theme = useTheme();

    return (
        <Box
            id={"pillars"}
        >
            <Box
                bgcolor={"#f4f4f4"}
            >
                <Typography
                    variant={"h1"}
                    align={"center"}
                    sx={{
                        padding: {
                            xs: theme.spacing(8, 0, 0, 0),
                            md: theme.spacing(12, 12, 0, 12)
                        }
                    }}
                    component={motion.h1}
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={inView}
                    viewport={{ once: true }}
                >
                    Os Pilares da <br/><strong>EnterScience</strong>
                </Typography>
                <motion.div
                    initial={{ opacity: 0, y: -200 }}
                    whileInView={inView}
                    viewport={{ once: true }}
                >
                    <CardGroup/>
                </motion.div>
            </Box>
            <img
                src={wave}
                width={"100%"}
                style={{
                    height: "17em",
                    objectFit: "cover",
                    objectPosition: "0px -1px"
                }}
            />
        </Box>
    );
}

export default Pillars;