import { Box, Card, Typography, useTheme } from "@mui/material";

const Board = props => {

    const {
        text,
        name,
        subtitle
    } = props;

    const theme = useTheme();

    return (
        <Box
            component={Card}
            padding={theme.spacing(4, 4, 0, 4)}
            margin={theme.spacing(0, 4)}
            display={"flex"}
            flexDirection={"column"}
            height={"100%"}
            raised
        >
            <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                height={"95%"}
            >
                <Typography
                    sx={{
                        flex: 1
                    }}
                >
                    {text}
                </Typography>
                <Typography
                    color={"secondary"}
                >
                    <b>{name}</b>
                </Typography>
                <Typography
                    color={"primary"}
                >
                    {subtitle}
                </Typography>
            </Box>
        </Box>
    );
}

export default Board;