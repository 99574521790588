import { useScrollTrigger } from "@mui/material";

const useMenubar = () => {

    const scrollTrigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 175
    });

    return {
        scrollTrigger
    };
}

export default useMenubar;