import { Box, Button, MenuItem, TextField, Typography, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { Controller } from "react-hook-form";

import InputMask from "react-input-mask";
import styles from "./index.module.css";
import formbodyBackground from "./assets/formbody-background.svg";
import useLandingForm from "components/useLandingForm";

const textFieldSx = {
    "& .MuiOutlinedInput-input": {
        color: "#fff"
    },
    "& .MuiFormLabel-colorPrimary": {
        color: "#fff"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "white"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "white"
    }
};

const Formbody = props => {

    const {
        title,
        text,
        children,
        ...others
    } = props;

    const {
        register,
        control,
        errors,
        onSubmit
    } = useLandingForm();

    const theme = useTheme();

    return (
        <Box
            sx={{
                padding: theme.spacing(8, 8, 0, 8)
            }}
            {...others}
        >
            <Box
                bgcolor={theme?.palette?.secondary?.main}
                color={"#fff"}
                borderRadius={5}
                position={"relative"}
                overflow={"hidden"}
                sx={{
                    backgroundImage: `url(${formbodyBackground})`,
                    backgroundSize: "cover",
                    // margin: {
                    //     xs: 1,
                    //     md: theme.spacing(8, 8, 0, 8)
                    // },
                    padding: {
                        xs: theme.spacing(8, 4),
                        md: theme.spacing(8, 10)
                    }
                }}
                component={motion.div}
                initial={{ opacity: 0, scale: 0.2 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
            >
                <Box>
                    <Typography
                        variant={"h3"}
                        align={"center"}
                    >
                        <strong>{title}</strong>
                    </Typography>
                    <Typography
                        variant={"h6"}
                        align={"center"}
                    >
                        {text}
                    </Typography>
                    <Box
                        marginTop={theme.spacing(4)}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: {
                                xs: "column",
                                md: "row"
                            }
                        }}
                    >
                        <Box
                            component={"form"}
                            display={"flex"}
                            flexDirection={"column"}
                            className={styles.form}
                            flex={1}
                            color={"white"}
                            onSubmit={onSubmit}
                            sx={{
                                margin: {
                                    xs: 0,
                                    md: theme.spacing(0, 8)
                                },
                                marginBottom: {
                                    xs: children ? 5 : 0,
                                    md: 0
                                },
                                width: {
                                    xs: "100%",
                                    md: "auto"
                                }
                            }}
                        >
                            <TextField
                                label={"Nome completo"}
                                helperText={errors?.name?.message}
                                error={Boolean(errors?.name)}
                                {...register("name", {
                                    required: "Insira um nome"
                                })}
                                sx={textFieldSx}
                            />
                            <TextField
                                label={"Seu melhor e-mail"}
                                placeholder={"exemplo@email.com"}
                                helperText={errors?.email?.message}
                                error={Boolean(errors?.email)}
                                {...register("email", {
                                    required: "Insira um e-mail",
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: "Insira um e-mail válido"
                                    }
                                })}
                                sx={textFieldSx}
                            />
                            <Controller
                                name={"phone"}
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <InputMask
                                        mask={"(99) 99999-9999"}
                                        {...field}
                                    >
                                        {(inputProps) => (
                                            <TextField
                                                label={"Telefone"}
                                                placeholder={"(99) 99999-9999"}
                                                helperText={errors?.phone?.message}
                                                error={Boolean(errors?.phone)}
                                                sx={textFieldSx}
                                                {...inputProps}
                                            />
                                        )}
                                    </InputMask>
                                )}
                            />
                            <TextField
                                label={"Do que você precisa?"}
                                {...register("need", {
                                    required: "Selecione um serviço"
                                })}
                                helperText={errors?.need?.message}
                                error={Boolean(errors?.need)}
                                sx={textFieldSx}
                                select
                            >
                                <MenuItem value={""}>
                                    ---
                                </MenuItem>
                                <MenuItem value={"Inteligência Artificial"}>
                                    Inteligência Artificial
                                </MenuItem>
                                <MenuItem value={"Automação e Controle"}>
                                    Automação e Controle
                                </MenuItem>
                                <MenuItem value={"Consultoria Especializada"}>
                                    Consultoria Especializada
                                </MenuItem>
                            </TextField>

                            <Button
                                variant={"contained"}
                                type={"submit"}
                                sx={{
                                    padding: theme.spacing(1.25)
                                }}
                            >
                                Enviar
                            </Button>
                        </Box>
                        {children && (
                            <Box>
                                {children}
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default Formbody;