import { Box, Typography, useTheme } from "@mui/material";

import logo from "assets/images/enterscience-logo.png";
import AboutUs from "./AboutUs";
import Contact from "./Contact";
import Social from "./Social";

const Footer = () => {

    const theme = useTheme();

    return (
        <Box>
            <Box
                display={"flex"}
                padding={theme.spacing(8)}
                sx={{
                    flexDirection: {
                        xs: "column",
                        lg: "row"
                    }
                }}
            >
                <Box
                    component={"img"}
                    src={logo}
                    sx={{
                        height: "55px",
                        width: "210px",
                        alignSelf: "center",
                        marginBottom: {
                            xs: 2,
                            lg: 0
                        },
                        marginRight: 3
                    }}
                />
                <AboutUs/>
                <Contact/>
                <Social/>
            </Box>
            <hr/>
            <Box
                padding={theme.spacing(1.5, 14)}
                display={"flex"}
                flex={1}
                sx={{
                    flexDirection: {
                        xs: "column",
                        md: "row"
                    },
                    justifyContent: {
                        xs: "center",
                        md: "space-between"
                    },
                    alignItems: "center"
                }}
            >
                <Typography>
                    EnterScience 2022 © Todos os direitos reservados 
                </Typography>
                <Typography>
                    <strong>Política de Privacidade</strong>
                </Typography>
            </Box>
        </Box>
    );
}

export default Footer;