export const cases = [
    {
        text: '"A GS encontrou na EnterScience o parceiro ideal para incorporar ao Zoombox, nossa plataforma de CRM, BigData & Analytics, novos níveis de sofisticação para predição do que chamamos de NBO (Next Best Offer), ou a próxima melhor oferta ser recomendada aos consumidores (aqui chamados de shoppers), engajados pela plataforma. A profundidade técnica e rigor científico com que o projeto foi executado demonstram claramente o valor e propósito da EnterScience, que estão alinhados com os valores que norteiam a GS, desde a sua criação."',
        name: "Thiago Simonato",
        subtitle: "VP de Negócios e Operações na GS Ciência do Consumo"
    },
    {
        text: '"A EnterScience é uma grande parceira e o braço de IA da plataforma HIT. Estava em nosso road map a inclusão de Inteligência Artificial na plataforma, mas não queríamos adotar uma caixa preta e sim dominar a tecnologia, técnicas refinadas, avançadas e com métodos que pudessem fazer frente as melhores plataforma de AIOPS do mercado. A EnterScience tem nos ajudado muito a tornar realidade a estratégia de IA no HIT AIOPS."',
        name: "Rafael Derrico",
        subtitle: "CEO na Health IT - HIT"
    }
];