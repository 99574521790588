import { Box, Button, TextField, Typography } from "@mui/material";

import formbodyBackground from "./assets/formbody-background.svg";
import useLogin from "./useLogin";

const Login = () => {

    const {
        email,
        password,
        handleEmail,
        handlePassword,
        loginMutate
    } = useLogin();

    return (
        <Box
            sx={{
                width: "100vw",
                height: "100vh",
                bgcolor: "pink",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white"
            }}
        >
            <Box
                sx={{
                    padding: 5,
                    borderRadius: 2,
                    bgcolor: "black",
                    display: "flex",
                    flexDirection: "column",
                    backgroundImage: `url(${formbodyBackground})`,
                    backgroundSize: "cover",
                    "& *": {
                        margin: .3
                    }
                }}
            >
                <Typography
                    variant={"h4"}
                    sx={{
                        marginBottom: 3
                    }}
                >
                    Entrar
                </Typography>
                <TextField
                    label={"E-mail"}
                    variant={"filled"}
                    value={email}
                    onChange={handleEmail}
                />
                <TextField
                    label={"Senha"}
                    variant={"filled"}
                    value={password}
                    onChange={handlePassword}
                    type={"password"}
                />
                <Button
                    variant={"contained"}
                    onClick={loginMutate}
                    sx={{
                        marginTop: 3
                    }}
                >
                    Entrar
                </Button>
            </Box>
        </Box>
    );
}

export default Login;