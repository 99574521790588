import { Box } from '@mui/material';
import { ProSidebar, SidebarContent, SidebarHeader, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import ListIcon from '@mui/icons-material/List';
import ToggleCollapseButton from './ToggleCollapseButton';
import WorkIcon from '@mui/icons-material/Work';
import ArticleIcon from '@mui/icons-material/Article';
import logo from "assets/images/enterscience-logo-branco.png";

const AdminPanel = props => {

    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapse = () => setCollapsed(!collapsed);
    
    return (
        <>
            <ProSidebar
                collapsed={collapsed}
            >
                <SidebarHeader>
                    <Box
                        component={"img"}
                        src={logo}
                        width={collapsed ? 50 : 175}
                        sx={{
                            padding: 2
                        }}
                    />
                </SidebarHeader>
                <SidebarContent>
                    <Menu>
                        <MenuItem
                            icon={<DashboardIcon/>}
                        >
                            <NavLink to={"/admin"}>
                                Dashboard
                            </NavLink>
                        </MenuItem>
                        <SubMenu
                            icon={<GroupIcon/>}
                            title={"Clientes"}
                        >
                            <MenuItem
                                icon={<ListIcon/>}
                            >
                                <NavLink to={"/admin/clients/registers"}>
                                    Registros
                                </NavLink>
                            </MenuItem>
                        </SubMenu>
                        <SubMenu
                            title={"Vagas"}
                            icon={<WorkIcon/>}
                        >
                            <MenuItem
                                icon={<ArticleIcon/>}
                            >
                                <NavLink to={"/admin/careers/registers-forms"}>
                                    Registros de formulário
                                </NavLink>
                            </MenuItem>
                            <MenuItem
                                icon={<WorkIcon/>}
                            >
                                <NavLink to={"/admin/careers/open-jobs"}>
                                    Vagas abertas
                                </NavLink>
                            </MenuItem>
                        </SubMenu>
                    </Menu>
                </SidebarContent>
            </ProSidebar>
            <ToggleCollapseButton
                collapsed={collapsed}
                toggleCollapse={toggleCollapse}
            />
        </>
    );
}

export default AdminPanel;