import { Box, Typography, useTheme } from "@mui/material";
import { cloneElement } from "react";
import { contactData } from "./data";

const Contact = () => {

    const theme = useTheme();

    return (
        <Box
            flex={1}
            marginBottom={2}
        >
            <Typography
                variant={"h5"}
                sx={{
                    mb: 2
                }}
            >
                <strong>Contato</strong>
            </Typography>
            {contactData.map(({ icon, text, href }, key) => (
                <Box
                    key={key}
                    component={"a"}
                    href={href}
                    sx={{
                        width: "fit-content",
                        color: theme.palette.secondary.main,
                        display: "flex",
                        alignItems: "center",
                        margin: theme.spacing(1, 0),
                        textDecoration: "none",
                        "& > *": {
                            transition: ".3s cubic-bezier(0.4, 0, 0.2, 1)"
                        },
                        "&:hover > *": {
                            color: theme.palette.primary.main
                        }
                    }}
                >
                    {cloneElement(icon, { sx: { m: .4 } })}
                    <Typography
                        sx={{
                            color: "#000000"
                        }}
                    >
                        {text}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
}

export default Contact;