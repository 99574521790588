import { Box, Button, Card, CardContent, Typography, useTheme } from "@mui/material";
import { useState } from "react";

import styles from "./index.module.css";
import SlideUpModal from "./Modal";

const Board = props => {

    const {
        title,
        text,
        cards,
        buttonLink
    } = props;

    const [modal, setModal] = useState(false);

    const theme = useTheme();
    
    const handleOpenModal = () => setModal(true);
    const handleCloseModal = () => setModal(false);

    return (
        <Box
            component={Card}
            className={styles.container}
            margin={theme.spacing(2)}
            flex={1}
        >
            <Box
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                className={styles.cardContent}
            >
                <Typography
                    variant={"h4"}
                    align={"center"}
                    color={theme?.palette?.secondary?.main}
                    sx={{
                        padding: theme.spacing(8),
                        paddingBottom: theme.spacing(4),
                    }}
                >
                    <strong>{title}</strong>
                </Typography>
                <Box
                    component={Typography}
                    align={"center"}
                    padding={theme.spacing(2, 5)}
                    flex={1}
                >
                    {text}
                </Box>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={handleOpenModal}
                    sx={{
                        margin: theme.spacing(3)
                    }}
                >
                    Saiba mais
                </Button>
            </Box>
            <SlideUpModal
                open={modal}
                onClose={handleCloseModal}
                title={<span>A <strong>Inteligência</strong> está presente em <strong>todas as soluções</strong></span>}
                cards={cards}
            />
        </Box>
    );
}

export default Board;