import DeleteButton from "common/Buttons/DeleteButton";
import { useMutation } from "react-query";
import { Post } from "requests";
import { ADMIN_URL } from "variables";

const Actions = props => {
    
    const {
        id
    } = props;

    const { mutate } = useMutation(async () => {
        const form = new FormData();
        form.append("id", id);
        return await Post(`${ADMIN_URL}/clients/delete`, form);
    });

    return (
        <DeleteButton
            title={"Deletar registro"}
            onClick={mutate}
        />
    );
}

export default Actions;