import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
import GradientButton from "common/Buttons/GradientButton";
import { useContext } from "react";
import { SlideUpModalContext } from "..";

const Board = props => {

    const {
        title,
        text,
        icon,
        ...others
    } = props;

    const theme = useTheme();
    const modalContext = useContext(SlideUpModalContext);

    const handleContactClick = () => modalContext.close();

    return (
        <Card sx={{ height: "100%", maxWidth: "50%" }}>
            <CardContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100%",
                    minHeight: 400
                }}
            >
                <Box
                    sx={{
                        padding: 4,
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        fontSize: "40px !important",
                        borderRadius: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        transition: ".3s",
                        border: "2px solid transparent",
                        mb: 6,
                        "&:hover": {
                            backgroundColor: "#fff",
                            color: theme.palette.primary.main,
                            borderColor: theme.palette.primary.main
                        }
                    }}
                >
                    {icon}
                </Box>
                <Typography
                    align={"center"}
                    variant={"h6"}
                    sx={{
                        mb: 2
                    }}
                >
                    <strong>{title}</strong>
                </Typography>
                <Typography
                    align={"center"}
                    sx={{
                        flex: 1
                    }}
                >
                    {text}
                </Typography>
                <GradientButton
                    gradientOrientation={"70deg"}
                    gradientColors={[
                        `${theme.palette.primary.main} 50%`,
                        `${theme.palette.secondary.main} 80%`
                    ]}
                    sx={{
                        borderRadius: 6
                    }}
                    onClick={handleContactClick}
                    component={"a"}
                    href={"#contact-us"}
                >
                    <strong>Entrar em contato</strong>
                </GradientButton>
            </CardContent>
        </Card>
    );
}

export default Board;