import { ButtonBase } from "@mui/material";
import { motion } from "framer-motion";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { grey } from "@mui/material/colors";

const ToggleCollapseButton = props => {

    const {
        collapsed,
        toggleCollapse
    } = props;
    
    return (
        <ButtonBase
            component={motion.div}
            sx={{
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: grey[800],
                padding: 1,
                borderRadius: "100%",
                zIndex: 1010,
                color: "white",
                top: 25
            }}
            variants={{
                open: {
                    left: "250px"
                },
                closed: {
                    left: "60px",
                    rotate: 180
                }
            }}
            animate={collapsed ? "closed" : "open"}
            transition={{
                duration: 0.3
            }}
            onClick={toggleCollapse}
        >
            <ArrowForwardIosIcon
                fontSize={"small"}
            />
        </ButtonBase>
    );
}

export default ToggleCollapseButton;