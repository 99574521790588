import { Box, Typography } from "@mui/material";

const AboutUs = () => {
    return (
        <Box
            flex={1}
            marginBottom={2}
        >
            <Typography
                variant={"h5"}
                sx={{
                    mb: 2
                }}
            >
                <strong>Sobre Nós</strong>
            </Typography>
            <Typography>
                O nosso objetivo é preencher a lacuna existente entre o mercado e as universidades por meio do desenvolvimento de soluções inteligentes, inovadoras e eficientes.
            </Typography>
        </Box>
    );
}

export default AboutUs;