import { Box, Dialog, DialogContent, IconButton, Slide, Typography } from "@mui/material";
import { createContext, forwardRef } from "react";
import Board from "./Board";
import CloseIcon from '@mui/icons-material/Close';

export const SlideUpModalContext = createContext();

const SlideUpTransition = forwardRef((props, ref) => <Slide direction={"up"} ref={ref} {...props}/>)

const SlideUpModal = props => {

    const {
        title,
        cards = [],
        onClose = () => {},
        ...others
    } = props;

    return (
        <Dialog
            fullScreen
            TransitionComponent={SlideUpTransition}
            onClose={onClose}
            {...others}
        >
            <DialogContent
                sx={{
                    backgroundImage: "linear-gradient(180deg, #0D1E9F 0%, #00CEFF 100%)",
                    color: "#fff"
                }}
            >
                <Typography
                    variant={"h4"}
                    align={"center"}
                    sx={{
                        mt: 5,
                        mb: 7
                    }}
                >
                    {title}
                </Typography>
                <IconButton
                    sx={{
                        position: "absolute",
                        right: 15,
                        top: 15,
                        color: "#fff"
                    }}
                    onClick={onClose}
                >
                    <CloseIcon/>
                </IconButton>
                <SlideUpModalContext.Provider
                    value={{
                        close: onClose
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: {
                                xs: "column",
                                md: "row"
                            },
                            alignItems: "center",
                            justifyContent: "center",
                            "& > *": {
                                m: 1,
                                flex: 1,
                                minHeight: 400
                            }
                        }}
                        id={"cards"}
                    >
                        {cards?.map((card, cardKey) => (
                            <Board
                                {...card}
                                key={cardKey}
                            />
                        ))}
                    </Box>
                </SlideUpModalContext.Provider>
            </DialogContent>
        </Dialog>
    );
}

export default SlideUpModal;