import { Box, Typography, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import CardGroup from "./CardGroup";

import styles from "./index.module.css";

const initial = {
    opacity: 0,
    y: 40
};

const inView = {
    opacity: 1,
    y: 0,
    transition: {
        opacity: {
            duration: 1
        },
        y: {
            duration: .5
        }
    }
};

const Services = () => {

    const theme = useTheme();

    return (
        <Box
            className={styles.container}
            id={"services"}
            sx={{
                padding: {
                    xs: theme.spacing(8, 0, 0, 0),
                    md: theme.spacing(8, 8, 0, 8)
                }
            }}
        >
            <Typography
                component={motion.h2}
                variant={"h1"}
                sx={{
                    padding: {
                        xs: 3,
                        sm: 0
                    }
                }}
                initial={initial}
                whileInView={inView}
                viewport={{ once: true }}
            >
                O nosso core é a <br/><strong>Inteligência</strong>
            </Typography>
            <Typography
                component={motion.p}
                sx={{
                    padding: {
                        xs: 3,
                        sm: 1
                    }
                }}
                initial={initial}
                whileInView={inView}
                viewport={{ once: true }}
            >
                A inteligência está sempre presente no desenvolvimento dos nossos projetos. <br/>Esse é o nosso diferencial. Esse é o futuro.
            </Typography>
            <CardGroup/>
        </Box>
    );
}

export default Services;