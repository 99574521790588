import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from 'swiper';

import { Box } from "@mui/material";

import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/css';
import "./carousel.css";
import styles from "./index.module.css";

const Carousel = props => {

    const {
        children,
        arrows = true,
        swiperProps = {},
        slidesProps = {},
        ...other
    } = props;


    if (children?.filter(x => x !== undefined)?.length === 0) return null;
    return (
        <Box
            component={Swiper}
            className={styles.mySwiper}
            slidesPerView={1} 
            loopFillGroupWithBlank={true}
            pagination={{
                clickable: "true"
            }}
            navigation={arrows}
            {...other}
            modules={[Navigation, Pagination]}
        >
            {children?.map((child, key) => child !== null && child !== undefined && (
                <SwiperSlide 
                    key={key}
                    style={{
                        width: "fit-content !important",
                        height: "100%"
                    }}
                    {...slidesProps}
                >
                    {child}
                </SwiperSlide>
            ))}
        </Box>
    );
}

export default Carousel;