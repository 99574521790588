import { Box, ButtonBase, Portal } from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import useResponsiveMenu from "./useResponsiveMenu";
import TopList from "./TopList";

const ResponsiveMenu = props => {

    const {
        scrollTrigger,
        items = []
    } = props;

    const {
        drawer,
        toggleDrawer
    } = useResponsiveMenu();

    return (
        <Box
            sx={{
                flex: 1,
                color: scrollTrigger ? "#000" : "#fff",
                display: {
                    xs: "flex",
                    md: "none"
                },
                justifyContent: "flex-start"
            }}
        >
            <Box
                component={ButtonBase}
                onClick={toggleDrawer}
                sx={{
                    borderRadius: "7px",
                    padding: .6
                }}
            >
                {drawer ? <CloseIcon/> : <MenuIcon/>}
            </Box>
            <Portal
                container={document.getElementById("root")}
            >
                <TopList
                    items={items}
                    open={drawer}
                    scrollTrigger={scrollTrigger}
                />
            </Portal>
        </Box>
    );
}

export default ResponsiveMenu;