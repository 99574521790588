import { Box, Typography, useTheme } from "@mui/material";
import { images } from "./data";
import Carousel from "components/Carousel";

const Clients = () => {

    const theme = useTheme();

    return (
        <Box
            id={"clients"}
            sx={{
                padding: {
                    xs: theme.spacing(8, 0, 0, 0),
                    md: theme.spacing(8, 8, 0, 8)
                }
            }}
        >
            <Typography
                variant={"h1"}
                align={"center"}
                sx={{
                    margin: theme.spacing(4, 0)
                }}
            >
                Conheça os nossos <strong>Clientes e Parceiros</strong>
            </Typography>
            <Carousel
                loop={true}
                slidesPerView={1}
                arrows={false}
                style={{
                    width: "80%"
                }}
                breakpoints={{
                    500: {
                        slidesPerView: 3
                    },
                    1000: {
                        slidesPerView: 5
                    }
                }}
            >
                {images?.map((image, key) => (
                    <img
                        src={image}
                        key={key}
                        width={"100%"}
                    />
                ))}
            </Carousel>
        </Box>
    );
}

export default Clients;