import { useState } from "react";

const useResponsiveMenu = props => {

    const [drawer, setDrawer] = useState(false);

    const toggleDrawer = () => setDrawer(!drawer);

    return {
        drawer,
        toggleDrawer
    };
}

export default useResponsiveMenu;