import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import Board from "./Board"
import useClientsRegisters from "components/Clients/useClientsRegisters";

const ClientsRegistersTable = () => {

    const {
        clients,
        refetch
    } = useClientsRegisters();

    return (
        <TableContainer
            component={Paper}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell>E-mail</TableCell>
                        <TableCell>Telefone</TableCell>
                        <TableCell>Necessidade</TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {clients?.map((client, clientKey) => (
                        <Board
                            key={clientKey}
                            {...client}
                        />
                    ))}
                </TableBody>
            </Table>
            {clients?.length == 0 && (
                <Typography
                    align={"center"}
                    sx={{
                        padding: 2
                    }}
                >
                    Sem registros
                </Typography>
            )}
        </TableContainer>
    );
}

export default ClientsRegistersTable;