import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import publicRoutes from "./Public";
import publicOnly from "./PublicOnly";
import privateRoutes from "./Private";
import { getCookie } from "utils/cookie";

const RoutesContainer = () => {

    const [routes, setRoutes] = useState(publicRoutes);

    const token = getCookie("token");

    const handleTokenChange = () => {
        if (token) {
            setRoutes([...privateRoutes, ...publicRoutes]);
        } else {
            setRoutes([...publicOnly, ...publicRoutes]);
        }
    }

    useEffect(handleTokenChange, [token]);

    return (
        <Routes>
            {routes?.map((route, routeKey) => (
                <Route
                    key={routeKey}
                    {...route}
                />
            ))}
        </Routes>
    );
}

export default RoutesContainer;