import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export const contactData = [
    {
        icon: <PhoneIcon/>,
        text: "(17) 99632-5593",
        href: "tel:17996325593"
    },
    {
        icon: <WhatsAppIcon/>,
        text: "Nosso WhatsApp",
        href: "https://api.whatsapp.com/send?phone=5517996325593&text=%20"
    },
    {
        icon: <MailOutlineIcon/>,
        text: "enterscience@enterscience.com.br",
        href: "mailto:enterscience@enterscience.com.br"
    },
    {
        icon: <LocationOnIcon/>,
        text: "Av. das Hortências, 635 - Jardim dos Seixas, São José do Rio Preto - SP, 15061-080, Brasil",
        href: "https://goo.gl/maps/wrCxeBUNGFMhcibB6"
    },
]