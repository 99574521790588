export const cardsContent = [
    {
        title: "O Que Somos?",
        text: "Somos a ponte que conecta o mercado e as universidades por meio do desenvolvimento de soluções inteligentes, inovadoras e eficientes."
    },
    {
        title: "O Que Fazemos?",
        text: "Por meio do mapeamento de problemas dos nossos clientes, buscamos construir a solução mais eficiente utilizando um conjunto de técnicas e processos inovadores."
    },
    {
        title: "Por Que Nos Escolher?",
        text: "Nosso time é formado por Doutores e Mestres na área da Computação, o que nos coloca em posição de vanguarda em termos de conhecimento de tecnologias e estratégias inteligentes para soluções de problemas."
    }
];