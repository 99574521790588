export const setCookie = (key, value, expirationTimeSeconds = 3600) => {
    const expirationDate = new Date(Date.now() + expirationTimeSeconds * 1000).toUTCString();
    document.cookie = `${key}=${value}; expires=${expirationDate};`;
}

export const getCookie = (key) => {
    const cookieArray = document.cookie.replaceAll(" ", "").split(";");
    const valueByKey = cookieArray.find(x => x.startsWith(key));
    
    if (valueByKey) return valueByKey.split("=")[1];
    else return valueByKey;
}