import { Box, Typography } from "@mui/material";
import Menubar from "modules/Menubar";
import underConstructionBg from "./assets/underConstruction.svg";
import { itemsListData } from "./data";

const UnderConstruction = () => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                width: "100vw",
                flexDirection: {
                    xs: "column",
                    md: "row"
                }
            }}
        >
            <Menubar
                defaultBgcolor={"#272727"}
                triggeredBgcolor={"#272727"}
                defaultColor={"white"}
                triggeredColor={"white"}
                items={itemsListData}
            />
            <Box>
                <Typography
                    variant={"h1"}
                    color={"primary"}
                    sx={{
                        textAlign: {
                            xs: "center",
                            md: "left"
                        }
                    }}
                >
                    <strong>Opa!</strong>
                </Typography>
                <Typography
                    variant={"h4"}
                >
                    <strong>Página ainda em construção</strong>
                </Typography>
            </Box>
            <Box
                sx={{
                    width: {
                        xs: "85vw",
                        md: "50vw"
                    },
                    height: "50vh",
                    backgroundImage: `url(${underConstructionBg})`,
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat"
                }}
            />
        </Box>
    );
}

export default UnderConstruction;