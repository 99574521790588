import { useQuery } from "react-query";
import { Get } from "requests"; 
import { ADMIN_URL } from "variables";

const useClientsRegisters = () => {

    const { data, refetch } = useQuery(["clients"], async () => await Get(`${ADMIN_URL}/clients`));
    const {
        clients = []
    } = data || {};

    return {
        clients,
        refetch
    };
}

export default useClientsRegisters;