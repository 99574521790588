import { Box, useTheme } from "@mui/material";
import { cardsContent } from "./data";
import { motion } from "framer-motion";

import Board from "./Board";

const initial = {
    x: 20,
    opacity: 0
};

const inView = {
    opacity: 1,
    x: 0,
    transition: {
        x: {
            duration: .8
        },
        opacity: {
            duration: .5
        }
    }
}

const CardGroup = () => {

    const theme = useTheme();

    return (
        <Box
            marginTop={theme.spacing(3)}
            sx={{
                display: "flex",
                flexDirection: {
                    xs: "column",
                    md: "row"
                }
            }}
            component={motion.div}
            initial={initial}
            whileInView={inView}
            viewport={{ once: true }}
        >
            {cardsContent?.map((card, key) => (
                <Board
                    {...card}
                    key={key}
                />
            ))}
        </Box>
    );
}

export default CardGroup;