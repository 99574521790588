import { Box, List, ListItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

import styles from "./index.module.css";

const ItemsList = props => {
    
    const {
        items = [],
        ...others
    } = props;

    const navigate = useNavigate();

    return (
        <Box
            component={"nav"}
            sx={{
                flex: 1
            }}
            {...others}
        >
            <Box
                component={List}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"center"}
            >
                {items?.map((item, key) => (
                    <Box
                        component={ListItem}
                        padding={1}
                        width={"auto"}
                        key={key}
                        sx={{
                            "& a": {
                                color: "inherit"
                            }
                        }}
                    >
                        <a
                            href={item?.anchor}
                            className={styles.listItem}
                            onClick={() => item?.to && navigate(item?.to)}
                        >
                            {item?.label}
                        </a>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export default ItemsList;