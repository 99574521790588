import Menubar from "modules/Menubar";
import RoutesContainer from "./routes";

function App() {
	return (
		<div className="enterscience-root">
			<RoutesContainer/>
		</div>
	);
}

export default App;
