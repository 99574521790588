import { Box, Button, Typography, useTheme } from "@mui/material";

import bannerVideo from "./assets/banner.mp4";
import styles from "./index.module.css";

const Banner = () => {

    const theme = useTheme();

    return (
        <Box
            className={styles.container}
        >
            <video
                className={styles.video}
                src={bannerVideo}
                controls={false}
                autoPlay={true}
                muted
                loop
            />
            <Box
                className={styles.shadow}
                zIndex={0}
            />
            <Typography
                variant={"h2"}
                zIndex={1}
                sx={{
                    fontWeight: 100,
                    padding: {
                        xs: theme.spacing(5, 5),
                        md: theme.spacing(5, 15)
                    },
                    paddingTop: theme.spacing(20)
                }}
            >
                Projetos e Consultoria em <strong style={{ fontWeight: "bolder" }}>Inovação Tecnológica</strong>
            </Typography>
            <Typography
                variant={"h5"}
                zIndex={1}
                sx={{
                    fontWeight: 300,
                    marginLeft: {
                        xs: theme.spacing(5),
                        md: theme.spacing(15)
                    }
                }}
            >
                <strong>Nossa missão é desenvolver soluções inovadoras que agregam valor aos negócios dos nossos clientes</strong>
            </Typography>
        </Box>
    );
}

export default Banner;