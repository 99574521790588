import { Box, Paper, Card, TextField, Typography } from "@mui/material";
import ClientsRegistersTable from "./Table";
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

const ClientsRegisters = () => {
    return (
        <Box>
            <Paper
                sx={{
                    padding: 1,
                    display: "inline-flex",
                    width: "100%",
                    boxSizing: "border-box",
                    alignItems: "center"
                }}
            >
                <Typography
                    sx={{
                        padding: 2
                    }}
                >
                    <strong>Clientes</strong>
                </Typography>
                <TextField
                    fullWidth
                    placeholder={"Pesquisar"}
                    InputProps={{
                        startAdornment: (
                            <SearchIcon/>
                        )
                    }}
                />
            </Paper>
            <Card
                sx={{
                    m: 2.5
                }}
                raised
            >
                <ClientsRegistersTable/>
            </Card>
        </Box>
    );
}

export default ClientsRegisters;