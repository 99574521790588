import { ButtonBase } from "@mui/material";

const GradientButton = (props) => {

    const {
        children,
        gradientColors = ["#fff", "red"],
        gradientTransition = ".4s ease-in-out",
        gradientOrientation = "to right",
        sx = {},
        ...others
    } = props;

    return (
        <ButtonBase
            sx={{
                padding: 2,
                borderRadius: 1,
                position: "relative",
                overflow: "hidden",
                color: "#fff",
                "&:before": {
                    content: '""',
                    position: "absolute",
                    backgroundImage: `linear-gradient(${gradientOrientation}, ${gradientColors})`,
                    width: "200%",
                    height: "100%",
                    left: 0,
                    transition: gradientTransition
                },
                "&:hover:before": {
                    transform: "translateX(-50%)"
                },
                ...sx
            }}
            {...others}
        >
            <span
                style={{ zIndex: 1 }}
            >
                {children}
            </span>
        </ButtonBase>
    );
}

export default GradientButton;