import { Box } from "@mui/material"
import Menubar from "modules/Menubar";
import Banner from "./Banner";
import Services from "./Services";
import Pillars from "./Pillars";
import OurMission from "./OurMission";
import Formbody from "./Formbody";
import bannerEnterscience from "./assets/enterscience-banner.jpg";
import SuccessCases from "./SuccessCases";
import Clients from "./Clients";
import Footer from "./Footer";

const Landing = () => {
    return (
        <Box>
            <Menubar/>
            <Banner/>
            <Services/>
            <Pillars/>
            {/* <OurMission/>
            <Formbody
                title={"Precisa de ajuda para o seu negócio ser mais eficiente?"}
                text={"A nossa equipe te dará soluções para o seu negócio. Preencha o formulário abaixo."}
            /> */}
            <Box
                sx={{
                    backgroundImage: `url(${bannerEnterscience})`,
                    backgroundSize: "cover",
                    width: "100%",
                    aspectRatio: "16/9",
                    marginTop: 10
                }}
            />
            <SuccessCases/>
            {/* <Formbody
                title={"Saiba como ter um projeto inteligente"}
                text={"Solicite uma consultoria agora. Preencha o formulário abaixo e entraremos em contato."}
            /> */}
            <Clients/>
            <Formbody
                title={"Desenvolvemos soluções inteligentes personalizadas para nossos clientes"}
                text={"Precisa de ajuda com o seu projeto? Preencha o formulário abaixo e entraremos em contato."}
                id={"contact-us"}
            >
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1864.814548821253!2d-49.366665000000005!3d-20.806288!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xad1a4810ab14bc64!2sEnterScience!5e0!3m2!1sen!2sus!4v1653064639349!5m2!1sen!2sus"
                    width="300"
                    height="300"
                    style={{
                        border: 0
                    }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                >
                </iframe>
            </Formbody>
            <Footer/>
        </Box>
    );
}

export default Landing;