import { ButtonBase, Tooltip } from "@mui/material";

import DeleteIcon from '@mui/icons-material/Delete';
import { red } from "@mui/material/colors";

const DeleteButton = props => {

    const {
        title = "",
        ...others
    } = props;

    return (
        <Tooltip title={title} arrow>
            <ButtonBase
                sx={{
                    borderRadius: 1,
                    bgcolor: red[800],
                    padding: .5,
                    color: "#fff"
                }}
                {...others}
            >
                <DeleteIcon fontSize={"small"}/>
            </ButtonBase>
        </Tooltip>
    );
}

export default DeleteButton;