export const vAppbar = {
    default: ([defaultBg, triggeredBg]) => ({
        // backgroundColor: ['rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 0)']
        backgroundColor: [triggeredBg, defaultBg]
    }),
    triggered: ([defaultBg, triggeredBg]) => ({
        backgroundColor: [defaultBg, triggeredBg]
    })
};

export const vToolbar = {
    default: {
        height: "90px"
    },
    triggered: {
        height: "60px"
    }
};