import { Box, Card, Typography, useTheme } from "@mui/material";

import styles from "./index.module.css";

const Board = props => {

    const {
        title,
        text,
        buttonLink
    } = props;

    const theme = useTheme();
    
    return (
        <Box
            component={Card}
            className={styles.container}
            margin={theme.spacing(2)}
            flex={1}
        >
            <Box
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                className={styles.cardContent}
            >
                <Typography
                    variant={"h5"}
                    align={"center"}
                    sx={{
                        padding: theme.spacing(4, 1.5),
                        paddingBottom: theme.spacing(2),
                    }}
                >
                    <strong>{title}</strong>
                </Typography>
                <Box
                    component={Typography}
                    align={"center"}
                    color={"#535353"}
                    variant={"h6"}
                    padding={theme.spacing(0, 5, 0, 5)}
                    flex={1}
                >
                    {text}
                </Box>
            </Box>
        </Box>
    );
}

export default Board;