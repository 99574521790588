import { yellow } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const DEFAULT_THEME = createTheme({
    palette: {
        primary: {
            main: "#FF6A17",
            contrastText: "#fff"
        },
        secondary: {
            main: "#0D1E9F",
            contrastText: "#fff"
        },
        tertiary: {
            main: "#00CEFF"
        },
        error: {
            main: yellow[500]
        }
    },
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "white"
                }
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    ":before": {
                        borderBottom: "1px solid white"
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: "'Poppins', sans-serif"
                }
            }
        }
    }
});
