import { AppBar, Box, Button, Toolbar, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { vToolbar, vAppbar } from "./variants";

import useMenubar from "./useMenubar";
import logo from "assets/images/enterscience-logo.png";
import whiteLogo from "assets/images/enterscience-logo-branco.png";
import ItemsList from "./ItemsList";
import ResponsiveMenu from "./ResponsiveMenu";
import { itemsListData } from "./data";
import { useNavigate } from "react-router-dom";

const Menubar = props => {

    const {
        defaultBgcolor = 'rgba(255, 255, 255, 0)',
        triggeredBgcolor = 'rgba(255, 255, 255, 1)',
        defaultColor = "#fff",
        triggeredColor = "#000",
        items = itemsListData
    } = props;

    const {
        scrollTrigger
    } = useMenubar();

    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <>
            <AppBar
                component={motion.div}
                variants={vAppbar}
                initial={"triggered"}
                animate={scrollTrigger ? "triggered" : "default"}
                custom={[defaultBgcolor, triggeredBgcolor]}
                sx={{
                    padding: theme.spacing(0.3, .9),
                    boxShadow: "none",
                    color: scrollTrigger ? triggeredColor : defaultColor
                }}
            >
                <Toolbar
                    component={motion.div}
                    variants={vToolbar}
                    animate={scrollTrigger ? "triggered" : "default"}
                >
                    <ResponsiveMenu
                        items={items}
                        scrollTrigger={scrollTrigger}
                    />
                    <Box
                        component={"img"}
                        src={scrollTrigger ? logo : whiteLogo}
                        marginRight={theme.spacing(2)}
                        onClick={() => navigate("/")}
                        sx={{
                            height: {
                                xs: "35%",
                                sm: "40%",
                                md: "55%"
                            },
                            cursor: "pointer"
                        }}
                    />
                    <Box
                        sx={{
                            display: {
                                xs: "none",
                                md: "inherit"
                            },
                            flex: 1
                        }}
                    >
                        <ItemsList
                            items={items}
                        />
                    </Box>
                    <Box
                        sx={{
                            flex: {
                                xs: 1,
                                md: "inherit"
                            },
                            display: "flex",
                            justifyContent: "flex-end"
                        }}
                    >
                        <Button
                            component={"a"}
                            variant={"contained"}
                            color={"primary"}
                            href={"#contact-us"}
                            sx={{
                                padding: theme.spacing(1, 4),
                                alignSelf: "right",
                                fontSize: 14,
                                display: {
                                    xs: "none",
                                    md: "initial"
                                },
                                whiteSpace: "nowrap"
                            }}
                        >
                            Entre em contato
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
}

export default Menubar;